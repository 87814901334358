import React, {useState, useEffect} from "react"
import axios from 'axios';
import {Container,Row,Col,Button} from 'react-bootstrap';
import { Link, navigate } from "gatsby";
import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"

import {config} from '../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE, PROPERTY_DETAILS } from "../queries/common_use_query";

import { submitPresentationStatus } from "../queries/api_links";

import {isLoggedIn, isBrowser, logout, getUser } from "../services/auth"

const CreatePresentation = (props) => {
  let path_name = (props.location.pathname).split('/');
  let get_property_id = path_name[path_name.length-1];

  const [ Company_Profile, setCompanyProfile ] = useState(true);
  const [ Valuation_Day, setValuationDay ] = useState(false);
  const [ Case_Studies, setCaseStudies ] = useState(true);
  const [ success_msg, setSuccessMsg ] = useState('');
  const [ error_msg, setErrorMsg ] = useState('');

  const [ article_data, setArticleData ] = useState('');
  const { loading, error, data } = GET_ARTICLE('create-pre-valuation-presentation');

  const { loading:property_loading, error:property_error, data:property_data } = PROPERTY_DETAILS(get_property_id);

  useEffect(()=>{
    setArticleData(data && data.articles[0]);
  }, [data]);

  const submitData = async(present_type, present_status) => {
    var get_property_id = property_data && property_data.properties[0].id;
    const updateQry = submitPresentationStatus(get_property_id, present_type, present_status, property_data && property_data.properties[0]).then(res => {
      if(res && res.status === 200){
        //console.log("res_data", res.status, res.data);
        setSuccessMsg("Success: Thanks for submit presentation!");
        setTimeout(function(){
          setSuccessMsg('');
          if(isBrowser()) { window.location.href = "/app-dashboard" }
        },3000);
      } else{
        setErrorMsg("Error: Presentation could not submit!");
        setTimeout(function(){
          setErrorMsg('');
        },4000);
      }
    });
  }

  var preval_status = property_data && property_data.properties.length > 0 && property_data.properties[0].custom_and_pre_valution_presentation;

  //console.log("preval_status", preval_status);

  return(
    <>
      <SEO title="CreatePresentation" description="CreatePresentation" />

      <div className="dashboard-wrapper">
        <LeftNav page_name="Create Presentation" />
        {/* sidebar */}

        <div className="dashboard-content">
        <Container>
            <div className="content-wrapper section-wrapper">

                {(success_msg || error_msg) && <div className={`alert ${success_msg ? 'alert-success' : 'alert-danger'}`}>{success_msg || error_msg}</div> }

                <h2>{article_data && article_data.Title}</h2>

                <p className="fig-content font_20">{article_data && article_data.Content && HTMLReactParser(article_data.Content)}</p>

                <ul className="seller_config">
                    <li>
                        <span>Company Profile</span>
                        <div className="float-right">
                            <button className="btn edit_btn">Edit</button>
                            <button className={`btn ${Company_Profile === true ? 'on_btn' : 'off_btn'} `} onClick={() => setCompanyProfile(!Company_Profile)}>{Company_Profile === true ? 'on' : 'off'}</button>
                        </div>
                    </li>
                    <li>
                        <span>Valuation Day Agenda</span>
                        <div className="float-right">
                            <button className="btn edit_btn" onClick={() => {navigate(config.valuation_day)}}>Edit</button>
                            <button className={`btn ${Valuation_Day === true ? 'on_btn' : 'off_btn'} `} onClick={() => setValuationDay(!Valuation_Day)}>{Valuation_Day === true ? 'on' : 'off'}</button>
                        </div>
                    </li>
                    {/* <li>
                        <span>Select Case Studies</span>
                        <div className="float-right">
                            <button className="btn edit_btn">Edit</button>
                            <button className={`btn ${Case_Studies === true ? 'on_btn' : 'off_btn'} `} onClick={() => setCaseStudies(!Case_Studies)}>{Case_Studies === true ? 'on' : 'off'}</button>
                        </div>
                    </li> */}
                </ul>
            </div>

            <div className="seller_config_btns">
                <div className="float-right">
                    {
                      preval_status && (
                        <>
                        { preval_status.preval_presentation && (preval_status.preval_presentation.status !== "Draft" && preval_status.preval_presentation.status !== "Sent") && (
                          <button type="button" className="btn btn-secondary" onClick={() => submitData('preval', 'Draft')}>save as draft</button>
                        )}

                        { preval_status.preval_presentation &&  preval_status.preval_presentation.status !== "Sent" && (
                          <button type="button" className="btn btn-primary" onClick={() => submitData('preval', 'Sent')}>publish</button>
                        )}
                        
                        </>
                      )
                    }         
                           
                </div>
            </div>
        </Container>
        {/* container */}
      </div>
      {/* dashboard-content */}
      </div>
    </>
  )

}

export default CreatePresentation